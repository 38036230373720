<template>
  <v-app>
    <Navigation />
    <v-main class="main">
      <router-view/>
    </v-main>

    <Awards />
    <Footer />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Navigation from '@/components/Navigation.vue';
import Awards from '@/components/Awards.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Navigation,
    Awards,
    Footer,
  },
})
export default class App extends Vue {
  private drawer = false;

  private bg = 'transparent';

  protected mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  }

  private changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = 'white';
      } else {
        this.bg = 'transparent';
      }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.navigation {
  z-index: 5;
}

body {
  h1, h2, h3, h4, h5 {
   font-family: 'Cormorant', serif;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    color: #9d3fc9 !important;
    transition: ease-in 0.3s;

    &:hover {
      color: #6b208e !important;
      transition: ease-in 0.3s;
    }
  }
}
</style>