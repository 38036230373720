<template>
  <div id="awards">
    <div class="associations">
      <a href="https://www.speareducation.com/">
        <img :src="require(`@/assets/spear.png`)" title="SPEAR Logo" alt="SPEAR Logo" />
      </a>
      <a href="https://www.massdental.org/">
        <img :src="require(`@/assets/mdsicon.png`)" title="Massachusetts Dental Society Logo" alt="Massachusetts Dental Society Logo"/>
      </a>
      <a href="https://www.bostonmagazine.com/dentists/find/li-an-su/">
        <img :src="require(`@/assets/boston-top-dentist.png`)"  title="Boston Top Dentists Logo" alt="Boston Top Dentists Logo"/>
      </a>
      <a href="https://www.ada.org/">
        <img :src="require(`@/assets/American-Dental-Association-Logo.png`)"  title="American Dental Association Logo" alt="American Dental Association Logo" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Awards extends Vue {
}
</script>

<style lang="scss" scoped>
#awards {
  padding: 100px;

  .associations {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      max-width: 100%;
      margin: 10px;
    }
  }
}
</style>