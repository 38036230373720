<template>
  <div id="footer">
    <div id="footer-content">
      <div>
        <h1>Contact Us</h1>
        <div>
          Li-An Su, D.M.D
        </div>
        <div>
          161 Ash Street, Suite C, Reading, MA 01867
        </div>
        <div>Call <a href="tel:+17814396267">(781) 439-6267</a> or text <a href="sms:+17813156183">(781) 315-6183</a></div>
        <div>
          <a href="https://www.facebook.com/RadianceDentalMA"><v-icon color="white">mdi-facebook</v-icon></a>
          <v-icon color="white">mdi-yelp</v-icon>

        </div>
      </div>

      <div class="hours">
        <div><span>Monday</span><span>Closed</span></div>
        <div><span>Tuesday</span><span>8:00am to 5:00pm</span></div>
        <div><span>Wednesday</span><span>8:00am to 6:00pm</span></div>
        <div><span>Thursday</span><span>Closed</span></div>
        <div><span>Friday</span><span>8:00am to 4:00pm</span></div>
        <div><span>Saturday</span><span>8:00am to 4:00pm</span></div>
        <div><span>Sunday</span><span>Closed</span></div>
      </div>
    </div>

    <div class="map">
      <iframe height="450" 
              style="border:0" 
              loading="lazy" 
              allowfullscreen 
              onload="this.width=screen.width"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d344931.052110381!2d-71.48161521783851!3d42.56227220606944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e30b00196d1c6d%3A0x8b0faf183a987919!2sRadiance%20Dental!5e0!3m2!1sen!2sus!4v1706585051385!5m2!1sen!2sus">
      </iframe> 
    </div>

    <div class="copyright">
      <span>Copyright &copy; {{copyrightYear}}</span> |
      <span>Website Design by <a href="https://terresa.io">terresa.io</a></span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  public get copyrightYear() {
    return new Date().getFullYear();
  }
}
</script>

<style lang="scss" scoped>
#footer {
  color: #FFFFFF;
  background-color: #333333;

  .map {
    margin: 0;
  }

  &-content {
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    flex-wrap: wrap;

    .hours {
      div {
        display: flex;
        justify-content: space-between;
        width: 300px;
      }
    }

    @media only screen and (max-width: 30em) {
      .hours {
        padding: 10px 0;
      }
    }
  }

  .copyright {
    text-align: center;
    padding: 10px 0 0 0;
  }
}
</style>