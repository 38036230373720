<template>
  <div class="landing">
    <div class="video-container">
      <div class="overlay"></div>
      <div class="welcome">
        <div class="text">
          <h1>Home of Happy Smiles</h1>
        </div>
        <div class="instructions">
          <v-btn href="tel:17814396267">
              Call <v-icon>mdi-phone</v-icon>
          </v-btn>
          <v-btn href="sms:7813156183">
              Text <v-icon>mdi-cellphone</v-icon>
          </v-btn>
          <v-btn href="https://app.nexhealth.com/appt/radiance-dental">
            book <v-icon>mdi-laptop</v-icon>
          </v-btn>
          <div>Schedule your appointment today!</div>
        </div>



        <!--<div class="scroll">
          <v-icon x-large :size="40">mdi-chevron-double-down</v-icon>
        </div> -->
      </div>
      <video autoplay muted loop playsinline :width="width" :height="height">
        <source :src="require(`../assets/${landing}`)" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Landing extends Vue {
  private height = 768;

  private width = 1366;

  private isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
  private landing = 'mobile-landing.mp4';
}
</script>

<style lang="scss" scoped>
.landing {
  @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@600&display=swap');
  position: relative;
  height: 100vh;

  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.4);
      z-index: 2;
    }

    .welcome {
      text-align: center;
      position: absolute;
      margin: 0 auto 0 50%;
      left: -315px;
      bottom: 2em;
      z-index: 3;
      // background-color: rgba(255, 255, 255, 0.7);
      filter: drop-shadow(3px 1px 2px white);

      @media only screen and (max-width: 30em) {
        max-width: 20em;
        left: -10em;
      }

      .text {
        margin: 20px auto;
        padding: 20px;
        border-bottom: 1px solid #302083;
        color: #302083;

        h1 {
          text-transform: uppercase;
          font-size: 3em;
          font-family: 'Heebo', sans-serif;

          @media only screen and (max-width: 30em) {
            font-size: 2em;
          }
        }
      }

      .instructions {
        font-size: 2em;

        @media only screen and (max-width: 30em) {
          font-size: 1.5em;
        }

        .v-btn {
          margin: 5px;
        }
      }

      .scroll {
        margin: 50px 0;
      }
    }

    video {
      /* Make video to at least 100% wide and tall */
      min-width: 100%; 
      min-height: 100%; 
      
      /* Setting width & height to auto prevents the browser from stretching or squishing the video */
      // width: auto;
      height: auto;
      
      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      z-index: 1;
    }
  }
}
</style>