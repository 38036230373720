<template>
  <div class="navigation">
    <v-app-bar
      :color="bg"
      fixed
      light
      elevate-on-scroll
      shrink-on-scroll
      style="border: 1px solid red;"
    >
      <img :src="require('../assets/radiance.png')"
        title="Radiance Dental logo"
        alt="Radiance Dental logo"
        style="height: 100%"
        height="40" />

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon 
        @click.stop="drawer = !drawer"
        class="nav-button"
        :class="{ scrolled: isScrolled }">
          <v-icon :size="isScrolled ? 60 : 80">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      id="drawer"
      v-model="drawer"
      right
      temporary
      fixed
      app
      hide-overlay
    >
      <v-list
        nav
      >
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
        >
          <router-link to="/">
            <v-list-item>
                <v-list-item-title>
                  Home
                </v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{ name: 'Service', params: { code: 'periodontal' }}">
            <v-list-item>
              <v-list-item-title>
                  Periodontal Treatment
              </v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{ name: 'Service', params: { code: 'esthetic' }}">
            <v-list-item>
              <v-list-item-title>
                  Esthetic Dentistry
              </v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{ name: 'Service', params: { code: 'implant' }}">
            <v-list-item>
              <v-list-item-title>
                  Implants and Dentures
              </v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{ name: 'Service', params: { code: 'pediatric' }}">
            <v-list-item>
              <v-list-item-title>
                  Pediatric Dentistry
              </v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{ name: 'Service', params: { code: 'other' }}">
            <v-list-item>
              <v-list-item-title>
                  Other
              </v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/meet-the-doctors">
            <v-list-item>
              <v-list-item-title>
                  Meet the Doctors
              </v-list-item-title>
            </v-list-item>
          </router-link>


          <a href="tel:+17814396267">
            <v-list-item>
              <v-list-item-title>
                  Call Us at (781) 439-6267
              </v-list-item-title>
            </v-list-item>
          </a>
          <a href="tel:+17813156183">
            <v-list-item>
              <v-list-item-title>
                  Text Us at (781) 315-6183
              </v-list-item-title>
            </v-list-item>
          </a>

          <a href="https://app.nexhealth.com/appt/radiance-dental"
            target='_blank' 
            style='text-decoration: none; margin: 50px 20px;' 
            referrerpolicy="no-referrer-when-downgrade"> 
              <img alt='Book Now' 
                src="https://storage.googleapis.com/nexassets/embed-button/button-secondary.svg" 
                style='border: 0; 
                margin: 0.25em;'/> 
          </a>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
  drawer = false;

  bg = 'transparent';

  isScrolled = false;

  protected created() {
    window.addEventListener('scroll', this.changeColor);
  }

  private changeColor() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      this.bg = 'white';
      this.isScrolled = true;
    } else {
      this.bg = 'transparent';
      this.isScrolled = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  color: rgba(0, 0, 0, 1) !important;
  padding: 10px;
  width: 80px !important;
  height: 80px !important;

  &.scrolled {
    width: 48px !important;
    height: 48px !important;
  }
}
</style>
