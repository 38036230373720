import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: () => import('../views/Contact.vue'),
  // },
  {
    path: '/meet-the-doctors',
    name: 'Doctors',
    component: () => import('../views/Su.vue'),
  },
  {
    path: '/service/:code',
    name: 'Service',
    component: () => import('../views/Services.vue'),
    props: true,
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
