<template>
  <div>
    <Landing />

    <div>
      <div class="home-content">
        <div class="welcome">
          <h1>Welcome to Radiance Dental</h1>
          <p> Radiance Dental is located in a professional building in beautiful downtown Reading, Massachusetts, just 12 miles north of Boston,
            a five-minute walk from the Reading commuter rail station. Ample parking is provided at the rear of the building.
            Our caring and knowledgeable staff is led by Dr. Li-An Su, who is dedicated to providing modern dental care in the most gentle,
            comfortable and relaxing manner. We understand that trust is the most precious thing in a relationship between the patient and the
            dental professional and we strive to earn your trust.</p>
        </div>
        <div class="cards">
          <v-card max-width="320">
            <v-img
              height="250"
              :src="require('../assets/11.jpg')"
              title="Family with radiant smiles"
              alt="Family with radiant smiles"
            ></v-img>
            <v-card-title>Family Dentistry</v-card-title>
            <v-card-text>
              <p>
                Radiance Dental is a dental practice dedicated to serving patients of all ages, from toddlers to senior citizens. We equip our
                office with the latest high-tech instruments and provide a full range of dental services to all members of your family.
                In addition, we have a multi-cultural staff able to communicate with patients in both English and Mandarin Chinese.
              </p>
            </v-card-text>
          </v-card>
          <v-card max-width="320">
            <v-img
              height="250"
              :src="require('../assets/14.jpg')"
              title="Couple smiling brightly"
              alt="Couple smiling brightly"
            ></v-img>
            <v-card-title>Esthetic Dentistry</v-card-title>
            <v-card-text>
              <p>
                Everyone wants a natural-looking perfect smile; sometimes that perfection comes with a little help. At Radiance Dental, Dr. Su uses
                modern techniques and materials, from teeth-whitening, to crowns and veneers, to beautify your smile. Please call <a href="tel:+17814396267">(781) 439-6267</a> or text <a href="sms:+17813156183">(781) 315-6183</a> today for a free Smile Consultation. Let us bring out the radiance in you! 
              </p>
            </v-card-text>
          </v-card>
          <v-card max-width="320">
            <v-img
              height="250"
              :src="require('../assets/12.jpg')"
              title="Child smiling with missing front tooth"
              alt="Child smiling with missing front tooth"
            ></v-img>
            <v-card-title>Dental Emergency Service</v-card-title>
            <v-card-text>
              <p>
                We are here for you in your times of need. Whether it is a toothache or a broken denture, we'll try to help you resolve the problem
                in the most timely, comfortable manner. We offer same-day denture repair in most cases and will try to see you promptly when you
                have a dental emergency. 
              </p>
            </v-card-text>
          </v-card>
          <v-card max-width="320">
            <v-img
              height="250"
              :src="require('../assets/10.jpg')"
              title="Radiance Dental staff group photo"
              alt="Radiance Dental staff group photo"
            ></v-img>
            <v-card-title>Environmentally Friendly Office</v-card-title>
            <v-card-text>
              <p>
                Like many of our neighbors on Earth, we care about the impact our existences create on the environment. From its inception in 2004,
                Radiance Dental has done its best in minimizing its footprint on the planet. We use "green" products, recycle everything possible
                and adapt dental equipments that can be sterilized and reused to limit waste to the landfill. To read about what we do to stay green,
                please visit the FAQ page.
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import Landing from '../components/Landing.vue';

  @Component({
    components: {
      Landing,
    },
  })
  export default class Home extends Vue {
  }
</script>

<style lang="scss" scoped>
.home-content {
  padding: 50px;

  .welcome {
    margin: 50px;
  }

  .cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 30em) {
    padding: 10px;

    .welcome {
      margin: 0;
    }

    .cards {
      justify-content: center;

      .v-card {
        margin: 10px 0;
      }
    }
  }
}
</style>
